.Table {
  width: 100%;
  padding: 12px;
  background: $bg-color;
  position: relative;
  &__Head__Col-1 {
    .personal-details {
      margin-left: -24px;
    }
    .case-status {
      margin-left: 8px;
    }
    .service-user {
      margin-left: -28px;
    }
  }
  .mt-10 {
    margin-top: 10px;
    align-items: center;
    @media only screen and (max-width: 768px) {
      margin: 0;
    }
  }
  .TableRow_Grid_Detail {
    .mb-8 {
      margin-bottom: 8px;
    }
    &:last-child {
      .mb-8 {
        margin-bottom: 0;
      }
    }
    @media only screen and (max-width: 768px) {
      .mb-8 {
        margin-bottom: 0px;
      }
    }
  }
  .grayout {
    color: $greyscale-grey-8;
  }
  &__Head {
    // position: absolute;
    // top: 0;
    // left: 0;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    background: $greyscale-white;
    border-bottom: 0.75px solid #8b93a291;
    display: none;

    @include DesktopLabelsRegular();

    & > div {
      padding: 12px 40px;
    }
  }

  &__Body {
    &__Row {
      width: 100%;
      display: flex;
      margin: 0.5rem 0;
      box-shadow: 0px 1px 2px rgba(132, 150, 179, 0.2);
      border-radius: 8px;
      border: 0.5px solid #a1a4aa91;
      background: $greyscale-white;
      flex-direction: row;
      justify-content: space-between;
      cursor: pointer;

      & .grid-container {
        grid-template-columns: 1fr;
      }

      & > div {
        padding: 0.5rem;
        display: flex;
        justify-content: flex-start;
      }

      &__Due {
        display: flex;
      }

      &__isEdit {
        border-radius: 8px;
        box-shadow: 0 1px 2px 0 rgba(132, 150, 179, 0.4);
        border: solid 0.8px var(--grey-6);
        background-color: var(--white);
        width: 60%;
      }

      &__Content {
        padding: 0.5rem 0.2rem;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;

        & .watching {
          margin-top: 0.4rem;
        }
      }

      .chips-container {
        text-align: left;
        display: inline-block;
        margin-top: 5px;
      }

      .chips-container-inline {
        display: inline-block;
        margin-right: 2px;
      }

      &__Box {
        border-radius: 8px;
        box-shadow: 0 1px 2px 0 rgba(132, 150, 179, 0.4);
        background-color: var(--white);
        border: 0.75px solid #8b93a291;
        text-align: left;
        display: flex;
        align-items: center;
        padding: 0.4rem;
        // margin-bottom: 10px;
        &:not(:first-child) {
          margin-top: 0.4rem;
        }
      }

      &__Arrow {
        width: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
      }
    }
  }

  @media only screen and (min-width: 1025px) {
    padding: 16px;

    &__Head {
      display: flex;
      padding: 0 1rem;

      &__Col-2 {
        position: relative;

        &__Typo {
          position: absolute;
          top: 0;
          left: -0.3rem;
        }
      }

      &__Col-3 {
        position: relative;

        &__Typo {
          position: absolute;
          top: 0;
          left: -1.2rem;
        }
      }
    }

    &__Body {
      // margin-top: 2rem;

      &__Row {
        width: 100%;

        .chips-container {
          // width: 60%;
        }

        & .grid-container {
          grid-template-columns: repeat(12, 1fr);
        }

        & > div {
          flex-direction: row;
        }

        &__Content {
          width: 100%;

          & .watching {
            margin-top: 0.4rem;
          }
        }
      }
    }
  }

  @media only screen and (min-width: 1025px) {
    & {
      padding: 24px;

      &__Head {
        display: flex;
        padding: 0 1.5rem;

        &__Col-2 {
          position: relative;

          &__Typo {
            position: absolute;
            top: 0;
            left: -0.3rem;
          }
        }

        &__Col-3 {
          position: relative;

          &__Typo {
            position: absolute;
            top: 0;
            left: -1.2rem;
          }
        }
      }
    }
  }
}

.border-hr {
  display: none;
}

.inline-badge {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .badge {
    margin-left: 8px;
  }
}

.error {
  background-color: $alert-error;
}

.orange {
  background-color: $alert-warning;
}

.due-container {
  .due-badge-conatiner {
    margin: 20px 0 10px;
  }
}

.dot-parent {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dot {
  height: 4px;
  width: 4px;
  background-color: gray;
  border-radius: 50%;
  margin: 0px 0.4rem;
}

.tasks__data {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;

  & > div {
    display: flex;
  }
}

.primary-details-badge {
  border-radius: 6px;
  border: none;
  padding: 2px 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: white;
  background-color: $preventx-blue;
  display: inline-flex;
  margin: 0.15rem;
  margin-top: 0.2rem;
}

.pageEndSpinner {
  padding: 1.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.smothscroll {
  scroll-behavior: smooth;
}

@media screen and (max-width: 768px) {
  .TableRow_Grid_Detail {
    margin-bottom: 16px;
    &:last-child {
      margin: 0;
      padding: 0;
      border-bottom: none;
      .border-hr {
        display: none;
      }
    }
    .border-hr {
      display: block;
      width: 100%;
      border-bottom: 0.75px solid #8b93a291;
      margin-top: 16px;
    }
    .grid-container {
      grid-row-gap: 0;
    }
    .Table__Body__Row__Box {
      margin: 0;
      max-width: 100%;
    }
    .chips-container {
      line-height: 0;
      // padding-bottom: 5px;
      margin-top: 15px;
    }
    .chips-container-inline {
      margin-bottom: 5px;
    }
    .dot-parent {
      display: none;
    }
    .Table_Row_Task {
      flex-direction: column;
      .typography {
        margin-bottom: 5px;
      }
    }
  }
  .Table__Body__Row__Content {
    flex: 1;
  }
}

.user-cont {
  justify-content: flex-start;
}

//Todaylist storybook style changes
.today_list_container {
  .Table {
    text-align: left !important;
  }
  &__overdue {
    margin-bottom: 16px;
  }
  &__overdue-text {
    margin-right: 8px;
  }
}
