.task__panel {
  padding: 12px 16px;
  border-radius: 8px 8px 0 0;
  border-bottom: solid 0.8px $greyscale-grey-5;
  display: flex;
  align-items: center;
}
.NewTaskModal {
  .select__button {
    width: 100%;
  }
  .modal_form_container {
    // padding: 24px 24px 0;
    .grid-container {
      grid-row-gap: 24px;
      row-gap: 24px;
    }
    .grid-item {
      min-height: auto;
    }
  }
  .modal-footer {
    .sb-btn--secondary {
      margin-right: 16px;
    }
  }
  .modal-date-container {
    // padding: 12px 24px 24px;
    margin-top: 16px;
    display: flex;
    align-items: center;
    .sb-btn {
      padding: 0;
      justify-content: flex-start;
      margin-right: 25px;
      min-height: auto;
      height: auto;
    }
  }
}

.gray-out {
  color: $greyscale-grey-8;
}

.notification_safeguarding {
  padding: 16px;
  border-radius: 8px 8px 0 0;
  border-bottom: solid 0.8px $greyscale-grey-5;
  display: flex;
  align-items: center;
  .alert {
    width: 100%;
  }
  .notification_redirect {
    display: flex;
    align-items: center;
  }
}

.NewTaskModal {
  .modal-body {
    padding: 30px 16px;
  }
}

.safeguarding-panel {
  .caseview-panel__header {
    justify-content: flex-start;
    .title {
      margin-right: 8px;
    }
  }
}
