.editsearch__container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .mr-25 {
    margin-right: 25px;
  }
  .editsearch__container__editbtn {
    .btn {
      display: flex;
      align-items: center;
      color: $preventx-blue;
      border-color: $preventx-blue;
      path {
        fill: $preventx-blue;
      }
    }
    span {
      margin-right: 8px;
    }
  }
  &__right {
    display: flex;
    align-items: center;
  }
}
