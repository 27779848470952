.triageReport {
  background-color: #eff2f5;
  height: 100%;
  width: 100%;

  &__container {
    max-width: 642px;
    margin: 0 auto;
    .sb-triage__row-title-name {
      word-break: break-word;
      white-space: pre-line;
    }
  }
  &__title {
    background-color: $greyscale-white;
    border: 1px solid #b6bdc8;
  }
  &__text {
    font-family: Roboto;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    padding: 14px 24px;
    color: $utility-overlay;
  }
}
