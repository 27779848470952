@mixin notification-message {
  background-color: white;
  border-bottom: 1px solid #b6bdc8;
  padding: 12px 8px 8px 16px;
}

@mixin viewCase {
  &__viewCase {
    padding-left: 24px;
    display: flex;
    align-items: center;
    margin-top: 14px;
  }
  &__viewCase__status {
    .bullet {
      margin-left: 8px;
    }
    .bullet__icon {
      background: $greyscale-grey-9;
    }
    display: flex;
  }

  &__delete {
    margin-left: auto;
  }
}

//Notification component style
.notification {
  font-family: Roboto-Regular, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  font-style: normal;
  font-weight: normal;
}

//Notification Card style
.notification-card {
  max-width: 367px;
  height: auto;
  border: 0.75px solid $greyscale-grey-5;
  box-shadow: 0px 4px 12px rgba(132, 150, 179, 0.4);
  border-radius: 8px;
  background: white;
  position: absolute;
  top: 64px;
  right: 54px;
  z-index: 1000;
  overflow: hidden;
  @media only screen and (max-width: 576px) {
    min-width: 0;
    max-width: 576px;
    width: 100%;
    min-height: 100vh;
    top: 55.75px;
    right: 0px;
    left: 0px;
    border-radius: 0;
  }

  //Notification body
  &__body {
    .infinite-scroll-component {
      max-height: 700px;
    }
    @media only screen and (min-width: 577px) {
      .infinite-scroll-component {
        max-height: 500px;
      }
    }
  }

  //Notifica Header and Tab parent component style
  &__header-tab {
    @media only screen and (max-width: 576px) {
      border-bottom: 1px solid #b6bdc8;
      background-color: $greyscale-white;
    }
  }
  &__tab {
    padding: 16px;
    border-bottom: 1px solid #b6bdc8;
    background: white;
    @media only screen and (max-width: 576px) {
      .tabs__menus {
        padding: 2px;
      }
    }
  }

  &__messages {
    height: auto;
    overflow: hidden;

    &--active {
      height: 245px;
      overflow: hidden;
      @media only screen and (max-width: 576px) {
        height: calc(100vh - 385px);
      }
    }
  }

  &__disabled {
    padding: 16px;
    border-bottom: 1px solid #b6bdc8;
  }
}

//Notification cases Message Component
.notify-message {
  &__header {
    display: flex;
    justify-content: space-between;
    // align-items: center;
    font-size: 14px;
    line-height: 20px;
    @media only screen and (max-width: 576px) {
      font-size: 16px;
    }
  }

  &__svg {
    margin-right: 6px;
  }
  &__title {
    margin-top: -3px;
    max-width: 240px;
    word-break: break-word;
  }
  &__time {
    color: $greyscale-grey-6;
    white-space: nowrap;
  }
}

//Notification Disabled component
.notification-disabled {
  @media only screen and (min-width: 576px) {
    max-width: 336px;
  }

  border: 1px solid $preventx-dark-blue;
  border-radius: 8px;
  padding: 6px 8px;
  margin: 0 auto;
  font-family: Roboto;
  font-size: 16px;
  line-height: 20px;

  &__header {
    display: flex;
    align-items: center;
    font-weight: 500;

    & > label {
      margin: -4px 0 0 8px;
      font-family: Roboto;
    }
  }

  &__header-message {
    color: $greyscale-grey-9;
    font-weight: 500;
  }

  &__message {
    margin: 2px 0 0 24px;
    color: $greyscale-grey-9;
    font-weight: normal;
  }

  &__icon {
    path {
      fill: $preventx-dark-blue;
    }
  }
}
//Notification Header component
.notification-header {
  font-size: 14px;
  background-color: $greyscale-grey-1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-radius: 8px 8px 0 0;
  border-bottom: 1px solid #b6bdc8;
  &__title {
    color: #161e27;
    font-size: 18px;
  }
  @media only screen and (min-width: 577px) {
    min-width: 368px;
  }
  @media only screen and (max-width: 576px) {
    background-color: $greyscale-white;
    font-size: 24px;
    line-height: 32px;
    padding-top: 24px;
  }
  &__settings {
    display: flex;
    justify-content: space-between;
    color: $preventx-blue;
    & svg {
      path {
        stroke: $preventx-blue;
      }
    }
    & label {
      @media only screen and (max-width: 576px) {
        display: none;
      }
    }
  }
  &__icon {
    margin-left: 8px;
  }
}

//Notification delete styles
.notification-delete {
  cursor: pointer;
  margin-left: auto;
  & > svg {
    path {
      stroke: $alert-error;
    }
  }
}

//Notification cases styles
.notification-cases {
  @include notification-message;
  &__caseId {
    display: flex;
    margin: 10px 0 14px 0;
    padding-left: 24px;
    font-size: 14px;
  }
  &__user {
    display: flex;
    padding-left: 9px;
  }
  &__userName {
    padding-left: 9px;
  }

  @include viewCase;
}

//Notification system
.notification-feature {
  @include notification-message;
  @include viewCase;
}

//Notification System Maintenance components
.notification-system {
  @include notification-message;
  &__viewStatus {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    padding-left: 24px;
  }
}

//Notification Footer
.notification-footer {
  height: 64px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: $greyscale-grey-1;
  &__button {
    margin: 16px;
    background-color: $greyscale-grey-2;
    color: $greyscale-grey-8;
  }
  @media only screen and (max-width: 576px) {
    &__button {
      width: 100%;
      & label {
        text-align: center;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}

//notification footer button color
.active-btn {
  background-color: $preventx-blue;
  color: $greyscale-white;
}

//notification case view
.notification-view-case {
  font-size: 12px;
  color: $greyscale-grey-9;
  line-height: 16px;

  & label {
    width: max-content;
    cursor: pointer;
  }
  @media only screen and (max-width: 576px) {
    font-size: 16px;
  }
}

//Notification Read status component
.notification-read-status {
  font-size: 12px;
  color: $preventx-blue;
  line-height: 16px;
  margin-right: 16px;
  & label {
    width: max-content;
    cursor: pointer;
  }
  @media only screen and (max-width: 576px) {
    font-size: 16px;
  }
}

//Nottification end scroll
.notification-end-scroll {
  // max-width: 336px;
  margin: 16px;
  padding: 16px;
  background: $greyscale-grey-1;
  border-radius: 8px;
  text-align: center;

  & > label {
    font-size: 14px;
    line-height: 20px;

    @media only screen and (max-width: 576px) {
      font-size: 16px;
    }
  }
}

//NoNotification Component
@mixin no-notification-card {
  font-family: Roboto;
  max-width: 576px;
  min-height: 160px;
  height: 100%;
  background: white;
  box-shadow: 0px 4px 12px rgba(132, 150, 179, 0.4);

  // border-radius: 8px;
  @media only screen and (max-width: 576px) {
    border: none;
    border-radius: 0;
  }
}
.no-notification {
  position: relative;
  @include no-notification-card;
  display: flex;

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 50px;
    gap: 16px;
    @media only screen and (max-width: 576px) {
      gap: 24px;
    }
  }

  &__heading {
    & label {
      font-size: 24px;
      line-height: 32px;
      letter-spacing: -0.5px;
      font-weight: 500;
      font-style: normal;
      text-align: center;
      @media only screen and (max-width: 576px) {
        font-size: 32px;
        line-height: 40px;
      }
    }
  }
  &__message {
    & label {
      font-size: 14px;
      line-height: 16px;
      text-align: center;
    }
  }
  &__button {
    @media only screen and (max-width: 576px) {
      width: 253px;
      position: absolute;
      bottom: 10px;
    }
    display: flex;
    align-items: center;
    justify-content: center;
    > svg {
      margin-left: 8px;
      path {
        stroke: white;
      }
    }
  }
}

//Notification update component

.notification-updated {
  @include no-notification-card;

  display: flex;
  justify-content: space-evenly;
  margin: 0 auto;

  &__container {
    font-family: Roboto;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 368px;
    padding: 0 24px;
    color: #161e27;
    gap: 16px;
    @media only screen and (max-width: 576px) {
      gap: 24px;
    }
  }

  &__heading {
    text-align: center;
    padding-top: 24px;
    & label {
      font-size: 24px;
      line-height: 32px;
      font-weight: 500;
    }
  }
  &__message {
    text-align: center;
    & label {
      font-size: 14px;
    }
  }
}
