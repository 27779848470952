// Notification settings
.notification-settings {
  .notification-footer {
    margin-top: 28px;
  }

  height: 100%;
  position: relative;

  &__text {
    color: $greyscale-grey-8;
    font-size: 12px;
    padding: 16px 64px 16px 16px;
  }

  @media only screen and (min-width: 576px) {
    max-width: 374px;
  }
  @media only screen and (max-width: 576px) {
    width: 100%;
    height: 90vh;
    .notification-footer {
      position: absolute;
      bottom: 0;
      width: 100%;
    }
  }
}

//Notification Header settings
.notify-header-settings {
  height: 48px;
  background-color: $greyscale-grey-1;
  padding: 16px;
  @media only screen and (max-width: 576px) {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
  }
}

//Notification settings alerts
.notify-toggle-alert {
  word-break: break-all;
  max-width: 576px;
  margin: 13px 16px;
  &__icon {
    margin-right: 8px;
  }
  &__title {
    margin-top: -2px;
  }
}
