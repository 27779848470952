@font-face {
  font-family: Roboto-Regular;
  src: url("../../assets/fonts/Roboto-Regular.woff") format("woff");
  src: url("../../assets/fonts/Roboto-Regular.woff2") format("woff2");
  src: url("../../assets/fonts/Roboto-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}



@font-face {
  font-family: Roboto-Medium;
  src: url("../../assets/fonts/Roboto-Medium.woff") format("woff");
  src: url("../../assets/fonts/Roboto-Medium.woff2") format("woff2");
  src: url("../../assets/fonts/Roboto-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: Roboto-Bold;
  src: url("../../assets/fonts/Roboto-Bold.woff") format("woff");
  src: url("../../assets/fonts/Roboto-Bold.woff2") format("woff2");
  src: url("../../assets/fonts/Roboto-bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
}
