//Sample Tested and Received
.sample-tested {
  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
  &__body {
    border: 0.75px solid $greyscale-grey-6;
    box-sizing: border-box;
    box-shadow: 0px 1px 2px rgba(132, 150, 179, 0.4);
    border-radius: 8px;
    // margin: 9px;
  }
  &__title {
    border-bottom: 0.75px solid $greyscale-grey-8;
    padding: 12px;
    .typography {
      margin-bottom: 4px;
    }
  }
  &__description {
    font-size: 14px;
    color: $greyscale-grey-9;
    flex-direction: column;
    .typography {
      margin-bottom: 4px;
    }
  }
  &__info {
    display: flex !important;
    padding: 12px;
    flex-direction: row !important;
  }

  &__date {
    display: flex;
    flex-direction: column;
    margin-right: 32px;

    &--title {
      color: $greyscale-grey-8;
      font-size: 12px;
    }
    &--date {
      color: $greyscale-grey-9;
      font-size: 14px;
    }
  }
}

//Test booking Component style sheet
.test-bookings {
  .UserServicePanelHeader {
    justify-content: flex-start;

    .test-bookings__count {
      font-size: 12px;
      color: $greyscale-grey-8;
      padding-left: 5px;
    }
  }
  &__sub-content {
    display: flex;
    justify-content: space-between;
    margin-left: 32px;
  }
  &__body {
    padding: 16px 16px 0 16px;
    &:last-child {
      padding-bottom: 16px;
    }
  }

  &__screen {
    display: flex;
    margin-bottom: 4px;
    .badge {
      margin-left: 8px;
    }
  }
  &__sample {
    font-size: 12px;
    color: $greyscale-grey-9;
  }
  &__complete {
    display: flex;
    .badge {
      margin-left: 8px;
      margin-top: -2px;
    }
  }
  &__info {
    padding: 16px;
    border-bottom: 0.75px solid $greyscale-grey-6;
  }
  &__description {
    border-bottom: 0.75px solid $greyscale-grey-6;
    font-size: 14px;
    padding: 16px;
    &:first-child {
      margin-bottom: 8px;
    }
    &:last-child {
      border-bottom: none;
    }
  }
  &__status {
    margin-left: 8px;
    .typography {
      font-size: 12px;
    }
    &--title {
      color: $greyscale-grey-8;
      font-size: 12px;
    }
    &--stat {
      color: $greyscale-grey-9;
      font-size: 12px;
    }
  }
}

// For Breadcrumbs
.lab-breadcrumbs {
  padding: 16px 24px;
  border-bottom: 0.75px solid $greyscale-grey-6;
  color: $greyscale-grey-9;
  ul {
    display: flex;
    list-style: none;
  }
  li {
    display: flex;
    align-items: center;
    font-size: 12px;
    path {
      stroke: $greyscale-grey-7;
    }
    svg {
      margin: 0 10px;
      height: 10px;
    }
  }
  &__title {
    font-size: 24px;
    margin: 4px 0;
  }
  &__status {
    display: flex;
    align-items: center;
    font-size: 14px;
    .badge {
      margin-left: 8px;
      font-size: 14px;
    }
  }
}

// For Record Summary Style Sheet
.record-summary {
  &__screen {
    display: flex;
    align-items: center;
    gap: 13px;
    // margin-left: 102px;
  }
  &__shareIcon {
    margin-right: 8px;
  }
}

//Attachment
.attachement {
  &__upload {
    display: flex;
    align-items: center;
    gap: 8px;
    color: $preventx-blue;
    margin-left: auto;
    path {
      stroke: $preventx-blue;
    }
  }
  &__body {
    padding: 16px 0 16px 16px;
  }
  &__info {
    display: flex;
    align-items: center;
  }
  &__title {
    margin: 0 8px;
    font-size: 14px;
  }
  &__type {
    font-size: 12px;
    color: #68707e;
  }
  &__download {
    display: flex;
    align-items: center;
    gap: 8px;
    color: $preventx-blue;
    margin-left: auto;
    margin-right: 17px;
    path {
      stroke: $preventx-blue;
    }
  }
}

//Laboratory Component Style
.laboratory {
  &__body {
    margin: 16px 16px;
  }
  &__description {
    display: flex;
    // width: 300px;
    // margin-right: 38px;
  }
  &__image {
    display: flex;
  }
  &__link {
    margin: 20px 0 20px 0;
    display: flex;
    align-items: center;
    a {
      color: $preventx-blue;
    }
    svg {
      margin-left: 13px;
    }
  }
}

// Additional Matrix Component Style
.additional-matrix {
  .UserServicePanelHeader {
    justify-content: flex-start;
  }
  &__badge {
    margin-left: 8px;
  }
}

// Event Log Component Style
.event-log {
  .UserServicePanelHeader {
    justify-content: flex-start;
  }
  &__badge {
    margin-left: 8px;
  }
}

// Patient Details
.patient-details {
  &__time-bullet {
    margin-left: 5px;
  }
  &__time-info {
    margin-left: 5px;
    color: $greyscale-grey-6;
    font-size: 14px;
  }
  &__add-info {
    width: 100px;
  }
}
