.case {
  padding: 0.5rem 1rem;

  &-history {
    &__title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;

      & h3 {
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
      }

      & > .select {
        &__button-selected {
          padding-right: 0.4rem;
        }
      }
    }

    @media only screen and (max-width: 360px) {
      &__title {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;

        & h3 {
          font-family: Roboto-Regular, Helvetica, Arial, Verdana, Tahoma,
            sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 32px;
        }

        & .select {
          width: 100%;
        }
      }
    }
  }
}

.profilescrollbar,
.activitylog-scrollbar {
  overflow-y: auto;
  height: calc(100vh - 140px);

  @media screen and (max-width: 770px) {
    height: calc(100vh - 140px);
  }
  @media screen and (max-width: 400px) {
    height: calc(100vh - 240px);
  }
}

.triages-card__body {
  height: calc(100vh - 200px);
  overflow-y: auto;
}

.casehistory__body {
  height: calc(100vh - 150px);
  overflow-y: auto;
}

.message__body {
  height: calc(100vh - 190px);
  overflow-y: auto;
}

.case-activitylog {
  & > .panel {
    border: none;
  }
  .sb-eventlog__activity__summary {
    padding: 0 !important;
    margin-top: 10px;
  }

  .infinite-scroll-component.scrollbar {
    height: 680px ;
  }
}

.cup-serviceprovider {
  max-width: 205px;
}

@media only screen and (max-width: 767px) {
  .cup-serviceprovider {
    max-width: 100%;
    width: 100%;
  }
}

.sb-message {
  .right-panel__header {
    margin-bottom: 10px;
  }
}
