.search {
  position: relative;
  margin: 56px auto 0 auto;
  box-sizing: border-box;
  box-shadow: 0px 4px 12px rgba(132, 150, 179, 0.4);
  border-radius: 8px;
  @include fonts();
  font-size: 12px;
  color: $greyscale-grey-9;
  width: 564px;
}

.panel_caseheader {
  height: 80px;
  padding: 16px;
  border-bottom: 0.75px solid $greyscale-grey-6;
  .panel__header {
    padding: 0 !important;
    border: none;
    min-height: auto;
  }
}
.panel__subheader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.search-result-head {
  width: 100%;
  margin-top: 16px;
  font-size: 14px;
}

.search-container {
  // .modal-body-container{
  //   padding: 24px;
  // }
  .no-search-conatiner {
    min-height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .no-search-icon {
      display: flex;
      margin-bottom: 60px;
      align-items: center;
      justify-content: center;
      position: relative;
      .search-icon {
        svg {
          transform: scale(5);
          path {
            stroke: $greyscale-grey-8;
          }
        }
      }
      .cross-icon {
        width: 45px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: white;
        position: absolute;
        left: -32px;
        top: 3px;
        svg {
          transform: scale(2);
        }
      }
    }
  }
  .mt-8 {
    margin-top: 8px;
  }
  // .modal-footer {
  //   justify-content: flex-end;
  //   &.modal-error-footer {
  //     justify-content: flex-end;
  //   }
  //   .userdetail_btn {
  //     .active {
  //       background-color: white;
  //       color: #04306b;
  //     }
  //     .sb-btn{
  //       .sb-btn__tag{
  //         margin-left: 8px;
  //       }
  //     }
  //   }
  //   .btn {
  //     margin-right: 16px;
  //     &:last-child {
  //       margin-right: 0;
  //     }
  //   }

  //   &.search-modal-footer {
  //     justify-content: space-between;
  //     .count-result {
  //       display: flex;
  //       align-items: center;
  //       .ml-8 {
  //         margin-left: 8px;
  //         font-size: 14px;
  //       }
  //     }
  //   }
  //   .btn {
  //     label {
  //       text-align: center;
  //     }
  //   }
  // }

  .search-title {
    // padding: 20px 20px 0;
    // margin-bottom: 24px;
  }
  .tab-container {
    .radio {
      padding: 16px 16px 0;
    }
    .textfield {
      margin: 0 40px;
      width: 270px;
    }
    &.user-container {
      .textfield {
        margin: auto;
        width: 100%;
      }
      .modal_form_container {
        padding: 0;
        margin: 16px 28px 0;
      }
    }
    .case-container {
      margin: 8px 28px 20px;
    }
  }
  .ml-8 {
    margin-left: 8px;
  }
  .txt-cap {
    text-transform: uppercase;
  }
  @media only screen and (max-width: 767px) {
    .tab-container {
      .textfield {
        width: 80%;
      }
    }
    .no-search-conatiner {
      padding: 20px;
      height: calc(100vh - 182px);
    }
    // .modal-footer {
    //   justify-content: flex-start;
    //   flex-direction: column;
    //   position: relative;
    //   &.modal-error-footer {
    //     position: absolute;
    //     bottom: 0;
    //     flex-direction: row;
    //     .btn--secondary {
    //       flex: 1;
    //       margin-right: 8px;
    //       height: 40px;
    //     }
    //     .btn--primary {
    //       width: auto;
    //       flex: 1;
    //     }
    //   }
    //   .search-loading {
    //     position: absolute;
    //     bottom: 80px;
    //     width: 100%;
    //     z-index: 9999;
    //   }
    //   .btn {
    //     margin-right: 0;
    //   }
    //   .mobile-full{
    //     width: 100%;
    //   }
    //   // .userdetail_btn {
    //   //   display: flex;
    //   //   align-items: center;
    //   //   justify-content: space-between;
    //   //   width: 100%;
    //   //   .sb-btn {
    //   //     display: flex;
    //   //     align-items: center;
    //   //     justify-content: center;
    //   //     margin-right: 8px;
    //   //     padding: 0;
    //   //     label {
    //   //       font-size: 13px;
    //   //     }
    //   //     &:last-child {
    //   //       margin-right: 0;
    //   //     }
    //   //   }
    //   // }
    // }
  }
}

.search-modal {
  .sb-modal-footer {
    .sb-btn__tag {
      margin-left: 8px;
    }
  }
}
