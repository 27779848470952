//Mixins
@mixin dropbox-shadow {
  background: $greyscale-white;
  border: 0.75px solid $greyscale-grey-8;
  box-sizing: border-box;
  box-shadow: 0px 4px 12px rgba(132, 150, 179, 0.4);
  border-radius: 8px;
}

@mixin nav-title-style {
  cursor: pointer;
  path {
    stroke: $preventx-blue;
  }
  padding: 12px 16px;
  &:hover {
    background-color: rgba($color: #000000, $alpha: 0.1);
  }
}
//Style for Nav settings
.nav-settings {
  position: absolute;
  top: 60px;
  right: 26px;
  width: 180px;
  z-index: 2000;

  &__title {
    @include nav-title-style;
  }
  
  /* DropShadow/Modal */
  @include dropbox-shadow;
  border: 0.75px solid $greyscale-grey-5;
}
