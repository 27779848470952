.tree-1 .tree-1 ul,
.tree-1 li {
  position: relative;
  list-style: none;
  margin-bottom: 18px;
}

.tree-1 ul li > :not(:first-child) {
  padding-left: 25px;
  margin-top: 16px;
}

.tree-1 li::before,
.tree-1 li::after {
  content: "";
  position: absolute;
  left: -18px;
}

.tree-1 li ::before {
  border-bottom: 0.75px solid $greyscale-grey-6;
  border-top-left-radius: 0;
  border-bottom-left-radius: 4px;
  top: 2px;
  left: -18px;
  width: 18px;
  height: 6px;
}

//vertical line
.tree-1 li ::after {
  border-left: 0.75px solid $greyscale-grey-6;
  height: calc(100% + 20px);
  width: 0px;
  top: -30px;
}

.tree-1 ul > li:last-child::after {
  top: -28px;
  height: 30px;
}

.tree-1 ul > :last-child > li {
  margin-bottom: 0;
}

.root-node {
  border-bottom: 1px solid $greyscale-grey-4;
  padding: 18px 16px;
  & > li {
    margin-bottom: 0;
    & > div > ul > div > li,
    > div > ul > div > ul {
      margin-top: 18px;
    }
    & > div > ul > div > li::before {
      content: none;
    }
  }
}
