.userservicepanel {
  border: 0.75px solid $greyscale-grey-6;
  border-radius: 8px;
  background-color: $greyscale-white;
  @media only screen and (max-width: 450px) {
    border: none;
    border-radius: 0;
  }
}

.UserServicePanelHeader {
  font-family: Roboto-Regular, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  min-height: 40px;
  border-bottom: 0.75px solid $greyscale-grey-6;

  &__title {
    color: $greyscale-grey-9;
  }
  &__edit {
    display: flex;
    align-items: center;
    cursor: pointer;
    svg {
      transform: scale(0.9);
    }

    & > p {
      margin-right: 8px;
      font-size: 14px;
      color: $preventx-blue;
    }
  }
  &__icon {
    path {
      stroke: #172fbd;
    }
  }

  @media only screen and (max-width: 450px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }
}
