.lab-report-container {
  height: 100%;
}
.lab-report {
  .userservicepanel {
    margin: 16px;
  }
  background: $greyscale-grey-1;
  display: flex;

  &__firstchild {
    width: 49%;
  }
  &__secondchild {
    width: 51%;
  }
}

//stylesheet for recordsummary
.record-summary {
  &__screen {
    padding-left: 10px;
    color: $preventx-blue;
  }
}

//For Event Log
.event_log {
  padding: 20px;
}

/* For Responsive */
@media only screen and (max-width: 768px) {
  .lab-report {
    flex-direction: column;
    &__firstchild {
      width: 100%;
    }
    &__secondchild {
      width: 100%;
    }
    &__first-layer {
      display: flex;
    }
  }
  .tableRow > p {
    min-width: 100px;
  }
  .sample-tested__body {
    width: 340px;
  }
}

@media only screen and (max-width: 360px) {
  .lab-report {
    &__firstchild {
      width: 100%;
      .UserServicePanelHeader {
        flex-direction: row;
      }
    }
    &__secondchild {
      width: 100%;
    }
    &__first-layer {
      flex-direction: column;
    }
  }
  .userservicepanel {
    border: 0.75px solid #8b93a2;
    border-radius: 8px;
  }
  .tableRow__view {
    margin-top: -26px;
  }
  // Test Booking
  .test-bookings {
    .jYILfo {
      flex-direction: column;
    }
    &__count {
      padding-left: 0 !important;
      font-size: 14px !important;
      margin-top: -6px;
    }
    &__sub-title {
      margin-top: 16px;
      margin-bottom: 4px;
      font-size: 14px;
    }
    &__sub-id {
      font-size: 14px;
      //margin-bottom: 16px;
    }
    &__sub-id::after {
      content: "";
      display: block;
      margin-top: 16px;
      border-bottom: 0.75px solid $greyscale-grey-6;
      margin-left: -16px;
      width: 112%;
    }
  }

  .test-bookings__sub-content {
    margin-top: 16px;
  }
  .sample-tested__date {
    margin-right: 32px;
  }
  .test-bookings__status--title {
    font-size: 14px !important;
  }
  .test-bookings--stat {
    font-size: 16px !important;
  }
  .test-bookings__complete .badge {
    margin-top: 0;
  }

  // For Attachment
  .attachement {
    &__info {
      width: 140px;
      svg {
        margin-top: -12px;
      }
    }
    &__type {
      margin-top: 18px;
    }
  }
  // For Sample Test
  .sample-tested {
    &__description {
      flex-direction: column;
    }
    &__title {
      span {
        margin-bottom: 4px;
      }
    }
    &__trans {
      margin-bottom: 4px;
      font-size: 14px;
      color: $greyscale-grey-9;
    }
    &__sample {
      font-size: 14px;
      color: $greyscale-grey-9;
    }
    &__date--title {
      font-size: 14px;
    }
    &__date--date {
      font-size: 16px;
    }
  }
}

.cup-labreport {
  padding: 24px;
  gap: 24px;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }

  &__report {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    &-md {
      display: flex;
      flex-direction: column;
      gap: 24px;
      @media screen and (max-width: 1024px) {
        flex-direction: row;
      }
      @media screen and (max-width: 767px) {
        flex-direction: column;
      }
    }
  }
  &__row {
    display: flex;
    padding: 8px 21px 8px 16px;
    border-bottom: 1px solid $greyscale-grey-5;
    &:last-child {
      border-bottom: 0;
    }
    @media screen and (max-width: 360px) {
      flex-flow: column;
    }

    &__title {
      min-width: 120px;
      @media screen and (max-width: 360px) {
        font-size: 14px;
        margin-bottom: 4px;
        display: block;
        width: 100%;
      }
    }
    &__content {
      display: flex;
      flex-grow: 1;
      align-items: center;
      justify-content: space-between;
      &__icon-text {
        display: flex;
        align-items: center;
        @media screen and (max-width: 767px) {
          align-items: flex-start;
          flex-wrap: wrap;
        }
      }
      &__col2 {
        display: flex;
        flex-grow: 1;
        align-items: center;
        @media screen and (max-width: 767px) {
          align-items: flex-start;
          flex-flow: column;
        }
        @media screen and (max-width: 360px) {
          flex-flow: row;
          flex-wrap: wrap;
          align-items: center;
          justify-content: space-between;
        }
      }

      @media screen and (max-width: 767px) {
        flex-flow: row;
        justify-content: space-between;
      }

      @media screen and (max-width: 360px) {
        flex-flow: row;

        justify-content: space-between;
      }
    }
  }
  &__testbooking {
    &__header {
      display: flex;
      @media screen and (max-width: 500px) {
        flex-direction: column;
      }
      &__status {
        &-divider {
          display: none;
        }
        @media screen and (max-width: 500px) {
          &-divider {
            display: block;
            margin-top: 16px;
          }
          margin-top: 32px;
          align-items: flex-start;
          justify-content: space-between;
        }
      }
    }
  }
}
