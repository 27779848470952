.tableRow {
  display: flex;
  align-items: center;
  gap: 20px;
  border-bottom: 0.75px solid $greyscale-grey-6;
  padding: 9px 0 9px 16px;
  line-height: 18px;
  &:last-of-type {
    border-bottom: none;
  }
  & > p {
    min-width: 130px;
  }

  &__title {
    color: $greyscale-grey-8;
    font-size: 14px;
  }
  &__children {
    border-bottom: none;
    color: $greyscale-grey-9;
    font-size: 14px;
  }

  &__view {
    display: flex;
    align-items: center;
    gap: 8px;
    color: $preventx-blue;
    margin-left: auto;
    margin-right: 17px;
  }

  @media only screen and (max-width: 450px) {
    flex-direction: column;
    gap: 4px;
    align-items: flex-start;
    &__children {
      font-size: 16px;
    }
  }
}
