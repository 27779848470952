.right-panel {
  position: relative;
  // z-index: 10;
  display: flex;
  flex-direction: column;
  padding: 16px;

  &__header {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    flex-wrap: wrap;

    @include screen(mobile) {
      // display: block;
      &__space-between {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      }
    }
    @media only screen and (max-width: 450px) {
      &__block {
        display: block !important;
      }
    }

    &-title {
      @include DesktopH4Regular();
    }

    &-actions {
      display: flex;
      flex-shrink: 0;
      color: $preventx-blue;
      align-items: center;
      & svg {
        margin-left: 10px;
        path {
          stroke: $preventx-blue;
        }
      }
      cursor: pointer;
      @include screen(mobile) {
        &__fit-content {
          // width: 100%;
        }
      }
    }
  }
}
.page-right-menus {
  position: absolute;
  display: block;
  right: -112px;
  transition: all 0.4s ease;
  height: 100%;
  padding: 18px 12px;
  width: 168px;
  background-color: $greyscale-white;
  border-left: 1px solid $greyscale-grey-6;
  z-index: 100;

  &:hover {
    @media screen and (min-width: 961px) {
      right: 0;
      z-index: 150;
    }

    & .page-right-menus {
      &__item {
        @media screen and (min-width: 960px) {
          label {
            margin-left: 0;
          }

          &--active {
            background-color: $preventx-blue;
            label {
              color: $greyscale-white;
            }
          }
        }
      }
    }
  }

  &__iconText {
    background-color: #04306b;
    color: #fff;
  }

  &__item {
    margin-bottom: 30px;
    width: 144px;
    color: $greyscale-white;
    border-radius: 8px;
    z-index: 20;
    * {
      cursor: pointer;
    }
    label {
      margin-left: 15px;
      color: $greyscale-grey-9;
    }

    span {
      align-items: center;
      justify-content: center;
      height: 32px;
      width: 32px;
      border-radius: 8px;
    }
    &--active {
      span {
        background-color: $preventx-blue;
      }
      path {
        stroke: $greyscale-white;
      }
    }
  }
}

.page-right-menu-wrapper {
  z-index: 150;
  &__content {
    border-left: 1px solid $greyscale-grey-5;
    margin-right: 56px;
    background-color: $greyscale-white;
    width: 315px;
    // height: 100%;
    overflow-y: hidden;

    @media screen and (min-width: 1225px) {
      width: 507px;
    }

    @include screen(tab) {
      position: absolute;
      height: 100%;
      width: calc(100% - 56px);
      right: 0;
    }
    @include screen(mobile) {
      left: 0;
      width: initial !important;
    }
  }
}

//right nav link

.redirectional-link {
  width: 100%;
  text-align: center;
}

.redirectional {
  display: flex;
  align-items: center;
  justify-content: center;
  @include screen(mobile) {
    border: 0.74px solid $preventx-blue;
    padding: 8px;
    width: 100%;
    border-radius: 8px;
    // margin-top: 16px;
  }
  &__title {
    color: #172fbd;
    margin-right: 06px;
  }
  &__icon {
    @media only screen and (max-width: 450px) {
      display: none;
    }
  }
}
a {
  text-decoration: none;
}
