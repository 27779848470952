//Account details stylesheet
.servicepage {
  width: 100%;
  display: flex;
  @media only screen and (max-width: 769px) {
    width: 100%;
  }
  &__leftContext {
    flex-grow: 1;
    @media only screen and (max-width: 769px) {
      width: 100%;
    }
    &__details {
      padding: 24px;
      background-color: $bg-color;
    }
  }
}

@mixin default-style {
  font-family: Roboto-Regular, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  font-style: normal;
  font-weight: normal;
  width: 100%;
  @media only screen and (max-width: 450px) {
    padding-bottom: 16px;
  }
}
.account {
  .panel__body {
    border-bottom: none;
  }

  @include default-style;
  margin-bottom: 24px;

  @media only screen and (max-width: 450px) {
    padding-top: 16px;
    border-radius: 0;
    border: none;
    width: 100%;
    margin: 0;
  }
  &__panelheader {
    font-weight: 400;
    color: $greyscale-grey-9;
  }

  &__panelbody {
    &__details__badge {
      margin-left: 12px;
    }
    padding: 0px;
    &--border {
      @media only screen and (max-width: 450px) {
        border-left: 0.75px solid $greyscale-grey-6;
        border-right: 0.75px solid $greyscale-grey-6;
      }
    }
  }
}

//style for right panel profile section
.account-right-panel {
  @media only screen and (max-width: 450px) {
    border: 0.75px solid $greyscale-grey-6;
    padding: 6px 0;
    border-radius: 8px;
  }
}
//PersonalInfo style sheet
.personal {
  .panel__body {
    border-bottom: none;
  }
  @include default-style;
  margin-bottom: 24px;
  @media only screen and (max-width: 450px) {
    margin: 0;
  }

  &__header {
    @media only screen and (max-width: 600px) {
      gap: 0px;
    }
  }

  &__panelheader {
    display: flex;
    @media only screen and (max-width: 450px) {
      flex-direction: column;
    }
  }

  &__details__info {
    display: flex;

    border-bottom: 0.75px solid $greyscale-grey-6;
    &:last-of-type {
      border-bottom: none;
    }
    @media only screen and (max-width: 450px) {
      flex-direction: column;
    }
  }
  &__details {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 8px 16px;
    line-height: 20px;
    .contact__details__optional {
      margin: 0;
    }
    @media only screen and (max-width: 600px) {
      gap: 0;
      justify-content: space-between;
    }
    & > p:first-child {
      color: $greyscale-grey-8;
      font-size: 14px;
    }
    & > p:last-child {
      border-bottom: none;
      color: $greyscale-grey-9;
      font-size: 14px;
    }
    @media only screen and (max-width: 450px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 04px;
      padding: 16px;
      & > p:last-child {
        font-size: 16px;
      }
    }
    & + .personal__details {
      border-left: 0.75px solid $greyscale-grey-6;
      @media only screen and (max-width: 450px) {
        position: relative;
        border-left: none;
        border-top: 0.75px solid $greyscale-grey-6;
      }
    }
    & > p {
      min-width: 130px;
    }
    &:last-child {
      padding-bottom: 05px;
    }
  }
}

//Contact Information style stylesheet
//Account details stylesheet
.contact {
  .panel__body {
    border-bottom: none;
  }
  @include default-style;
  border-radius: 8px;

  @media only screen and (max-width: 450px) {
    border-radius: 0;
    border: none;
    width: 100%;
    margin: 0;
  }
  &__details {
    display: flex;
    align-items: center;
    gap: 20px;
    border-bottom: 0.75px solid $greyscale-grey-6;
    padding: 10px 0 10px 16px;
    position: relative;
    &__optional {
      display: flex;
      width: 100%;
      color: $greyscale-grey-8;
      margin-right: 12px;
      font-size: 14px;
      & > p:first-child {
        flex-grow: 1;
        color: $greyscale-grey-9;
        font-size: 14px;
      }
      & > p:nth-child(2) {
        font-size: 12px;
        color: $greyscale-grey-8;
        @media only screen and (max-width: 450px) {
          position: absolute;
          top: 16px;
          right: 20px;
        }
      }
    }
    &__date {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      & > p:first-child {
        color: $greyscale-grey-9;
      }
      & > div {
        display: flex;
        & > p {
          font-size: 12px;
          padding: 4px 0;
          color: $greyscale-grey-9;
        }
        & > p:first-child {
          color: $greyscale-grey-8;
          margin-right: 4px;
        }
      }
      &__verify {
        display: flex;
        align-items: center;
        margin-right: 8px;
        & > p {
          padding-right: 7px;
          color: #172fbd;
        }
      }
    }

    @media only screen and (max-width: 450px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 04px;
      padding: 16px;
    }

    &:last-child {
      border-bottom: none;
      padding-bottom: 05px;
    }
    & > p {
      min-width: 130px;
    }

    & > p:first-child {
      color: $greyscale-grey-8;
      font-size: 14px;
    }
    & > p:nth-child(2) {
      color: $greyscale-grey-9;
    }
    & > p:last-child {
      border-bottom: none;
      padding-bottom: 05px;
      color: $greyscale-grey-9;
      font-size: 14px;
    }
  }
  &__badge {
    margin-right: 4px;
  }
}

//Status Bar

.statusbar {
  display: flex;
  justify-content: space-between;
  padding: 16px 32px 16px 29px;
  border-bottom: 1px solid $greyscale-grey-5;

  .sb-select__input {
    padding: 8px 12px;
  }

  &__header {
    gap: 16px;
    flex-wrap: wrap;
    width: 79%;

    @media only screen and (min-width: 768px) {
      align-items: center;
    }
    @media only screen and (max-width: 767px) {
      flex-direction: column;
      .sb-select__input {
        width: auto;
        margin-bottom: 16px;
      }
      .sb-select__options {
        width: auto;
      }
    }
  }
  &-dropdown {
    border: 1px solid $preventx-blue;
    border-radius: 8px;
    padding: 12px 16px;
    .sb-dropdown__menu {
      margin-top: 34px;
    }
    @media only screen and (max-width: 767px) {
      .sb-dropdown__menu-start {
        right: -50px;
      }
    }
  }

  @media only screen and (max-width: 767px) {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    // gap: 16px;
    padding: 16px 24px;
  }
  &__namesection {
    display: flex;
    align-items: center;
    gap: 21px;
    color: $greyscale-grey-9;
    font-size: 24px;
    letter-spacing: -0.5px;
    font-weight: 500;
    font-family: Roboto-Regular, Helvetica, Arial, Verdana, Tahoma, sans-serif;
    font-style: normal;
    max-width: 400px;
    word-break: break-word;
  }
  &__select {
    min-width: 290px;
    .sb-select__input__selected {
      min-width: 60px;
    }
  }
  &__icon {
    @media only screen and (max-width: 450px) {
      display: none;
    }
  }
  &__name {
    color: $greyscale-grey-9;
  }
  .select__button {
    width: 100%;
  }
}
