.caseview {
  flex-grow: 1;
  @media screen and (max-width: 950px) {
    margin-right: 56px;
  }
  .grid-container {
    grid-column-gap: 0;
  }
  @media only screen and (min-width: 1025px) {
    .grid-container {
      grid-column-gap: 16px;
    }
  }
}

.caseview-menus {
  position: absolute;
  display: block;
  right: -112px;
  transition: all 0.4s ease;
  height: 100%;
  padding: 18px 12px;
  width: 168px;
  background-color: $greyscale-white;
  border-left: 1px solid $greyscale-grey-6;
  z-index: 100;

  &:hover {
    @media screen and (min-width: 961px) {
      right: 0;
      z-index: 150;
    }

    & .caseview-menus {
      &__item {
        @media screen and (min-width: 960px) {
          p {
            margin-left: 0;
          }

          &--active {
            background-color: $preventx-blue;
            p {
              color: $greyscale-white;
            }
          }
        }
      }
    }
  }

  &__iconText {
    background-color: #04306b;
    color: #fff;
  }

  &__item {
    margin-bottom: 30px;
    width: 144px;
    color: $greyscale-white;
    border-radius: 8px;
    cursor: pointer;

    p {
      margin-left: 12px;
      color: $greyscale-grey-9;
    }
    span {
      align-items: center;
      justify-content: center;
      height: 32px;
      width: 32px;
      border-radius: 8px;
    }
    &--active {
      span {
        background-color: $preventx-blue;
      }
      path {
        stroke: $greyscale-white;
      }
    }
  }
}

.caseview-menu-wrapper {
  z-index: 150;
  &__content {
    border-left: 1px solid $greyscale-grey-6;
    margin-right: 56px;
    background-color: $greyscale-white;
    width: 472px;
    height: 100%;

    @include screen(tab) {
      position: absolute;
      height: 100%;
      width: 384px;
      right: 0;
    }
    @include screen(mobile) {
      left: 0;
      width: initial !important;
    }
  }
}
.results {
  background-color: $bg-color;
  // margin: 1rem 0rem;
  padding: 1.5rem;
  &.fullheight{
    height: 100%;
  }
}

.panel-action {
  margin-top: 10px;
  margin-left: 16px;
  cursor: pointer;
  display: none;

  & > span {
    margin-right: 8px;
  }

  path {
    stroke: $preventx-blue;
  }
  span,
  p {
    color: $preventx-blue;
  }
  p {
    margin-left: 8px;
  }
}

@media only screen and (max-width: 768px) {
  .panel-action {
    display: block;
    // margin: 0px;
    // margin-top: 20px;
    // margin-left: 20px;
  }

  .caseview-container {
    &__header {
      &-title {
        // flex-direction: column;

        .primary--blue {
          width: 100%;
        }
      }
    }
  }

  .caseview-container__card .title {
    @include DesktopLabelsRegular();
    line-height: 16px;
    color: $greyscale-grey-8;
  }

  .caseview-container__card .label {
    @include DesktoppRegular();
  }
}

.caseview_header_badge {
  font-family: Roboto-Regular, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-left: 0.3rem;
  border-radius: 4px;
  padding: 0.2rem;
  background-color: $alert-warning;
}
