* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
}
.scrollY {
  overflow-y: auto;
  overflow-x: hidden;
}
#root {
  height: calc(100% - 56px);
}
body,
html {
  font-family: Roboto-Regular, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  overflow: hidden;
  height: 100%;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
}
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
