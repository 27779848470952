// .userprofile {
//   // padding: 16px;
//   &__header {
//     font-family: Roboto;
//     font-style: normal;
//     font-weight: normal;
//     font-size: 18px;
//   }
// }

.userprofile {
  margin-bottom: 12px;
  .panel__body {
    border-bottom: none;
  }
}

.user-detail-close {
  display: none;
  @media only screen and (max-width: 450px) {
    display: flex;
    align-items: center;
    &__icon {
      margin-right: 8px;
    }
    &__text {
      color: #172fbd;
    }
  }
}

.user-detail {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
  @media only screen and (max-width: 450px) {
    flex-direction: column;
    align-items: flex-start;
  }

  &__header {
    font-family: Roboto-Regular, Helvetica, Arial, Verdana, Tahoma, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    color: $greyscale-grey-9;
    @media only screen and (max-width: 450px) {
      padding-bottom: 16px;
    }
  }

  &__view {
    display: flex;
    align-items: center;
    @media only screen and (max-width: 450px) {
      border: 0.74px solid $preventx-blue;
      padding: 8px;
      width: 100%;
      border-radius: 8px;
      text-align: center;
    }
  }
  &__typo {
    margin-right: 13px;
    font-size: 14px;
    color: $preventx-blue;
  }

  &__icon {
    @media only screen and (max-width: 450px) {
      display: none;
    }
  }
}
