.new-notes {
  &__form {
    &__input {
      margin-bottom: 16px;
    }
    &__section {
      &-title {
        font-size: 18px;
        line-height: 24px;
      }
      &-action {
        display: flex;
        color: $preventx-blue;
        align-items: center;
        & svg {
          margin-left: 10px;
          path {
            stroke: $preventx-blue;
          }
        }
        cursor: pointer;
        @include screen(mobile) {
          // width: 100%;
        }
      }
    }
    &__tasks {
      margin-bottom: 16px;

      &-heading {
        margin: 16px 0;
        font-size: 14px;
        line-height: 20px;
      }
      &-title {
        line-height: 20px;
        margin-bottom: 4px;
      }
      &__body {
        display: flex;
        align-items: center;
        font-size: 14px;
        &-due {
          color: #57576b;
          margin-right: 4px;
        }
        &-date {
          margin-right: 8px;
        }
      }
    }
    &__safeguarding {
      &__checks {
        & label {
          @include DesktopButtonsRegular();
        }
      }
      &__actions {
        display: flex;
        width: 100%;
        @include screen() {
          display: block;
        }
        justify-content: space-between;
        & > * {
          margin-top: 12px;
          width: 208px;
          @include screen() {
            width: 208px;
          }
        }
      }
    }
  }
}

.edit-note {
  width: 100%;
  &__radio {
    margin-bottom: 16px;
  }
  &__checks {
    margin-bottom: 24px;
  }
  &__header {
    color: $greyscale-grey-9;
    margin-bottom: 24px;
    border: 0.75px solid $greyscale-grey-6;
    border-radius: 8px;
    padding: 12px 12px 10px 12px;

    &-title {
      @include fonts(regular);
      font-size: 16px;
      margin-bottom: 4px;
      line-height: 20px;
      color: $greyscale-grey-9;
    }
    &-subtitle {
      @include fonts(regular);
      color: $greyscale-grey-9;
      display: inline-flex;
      align-items: center;
      font-size: 14px;
      line-height: 18px;
      color: $greyscale-grey-9;
      margin-bottom: 8px;
    }
    &-tags {
      @include fonts(regular);
      margin-right: 8px;
      font-size: 14px;
      height: 20px;
      line-height: 18px;
      padding: 2px 4px;
      border-radius: 4px;
      color: $greyscale-white;
    }
    &-caseid {
      color: $preventx-blue;
    }
  }
}

.associate-test {
  &__header {
    display: flex;
    @include font();
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 8px;
    &-subtitle {
      text-transform: uppercase;
      color: $greyscale-grey-8;
    }
  }
  &__outcomes > *:not(:last-child) {
    margin-right: 2px;
  }
}
.noteslist {
  &__pagination {
    &-divider {
      position: absolute;
      top: 0;
      left: 50%;
    }
  }
}
