.CaseList__MainPage {
  background-color: gray;
  height: 100%;
  width: 100%;
}

.hidden {
  display: none;
}

.caseList-filterButton {
  display: none;
  @include screen(tab) {
    display: block;
  }
}

.txt-gray {
  font-size: 12px;
  color: $greyscale-grey-8;
  margin-top: 5px;
  &:first-child {
    margin-top: 0;
  }
}

.Container {
  display: grid;
  background-color: #eee;
  height: 200px;
  grid-template-columns: 60% 40%;
  grid-gap: 1rem;
  width: 100%;
  margin: 0 auto;
  text-align: center;

  &__Header {
    display: flex;
    justify-content: space-between;
  }
  .left {
    // background-color: yellow;
  }

  .right {
    background-color: orange;
  }
}

.Case_list_Head {
  font-size: 16px;
}

.caselist {
  &__table {
    &__body {
      & .user-service-col {
        display: flex;
        justify-content: space-between;
        text-align: left;

        @media only screen and (max-width: 767px) {
          & > .grid-container {
            @include MobileH5Regular();
            grid-gap: 0;
          }
        }

        & .workList_badge {
          @include DesktopLabelsRegular();
          margin-bottom: 0.1rem;
        }

        & .user-status-col {
          & .badge {
            margin-bottom: 0.4rem;
          }
          & span {
            @include DesktopLabelsRegular();
            margin: 0.4rem 0;
          }
        }

        & .bullet-container {
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }
      }

      & .Safeguarding-col {
        text-align: left;
        div:first-child {
          margin-bottom: 4px;
        }
      }

      & .testing-col {
        text-align: left;
        &-result {
          &-status {
            display: inline-flex;
            margin: 0.4rem 0;
            @include DesktopLabelsRegular();

            & span:last-child {
              margin-left: 0.4rem;
              color: $greyscale-grey-8;
            }
          }

          &-chips {
            & > .chips {
              margin: 0.2rem;
            }
          }
        }
      }

      & .safguarding__item {
        padding-left: 0.8rem;
      }

      & .testing__item {
        // padding-left: 1.5rem;
      }

      & .PartnerTracing__item {
        text-align: left;
      }
    }
  }
}

.caselist__container {
  .editsearch-container-mobile {
    display: none;
  }
  .editsearch-container-web {
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .caselist__container {
    .editsearch-container-mobile {
      display: block;
    }
    .editsearch-container-web {
      display: none;
    }
    .contactDetails {
      display: none;
    }
  }
  .search-enabled {
    .page-header {
      border-bottom: none;
      padding: 16px 16px 0;
    }
    .editsearch-container-mobile {
      padding: 16px;
      display: flex;
      align-items: center;
      .editsearch__container {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
      }
      .editsearch__container__right {
        justify-content: space-between;
      }
      .editsearch__container__count {
        margin-bottom: 8px;
      }
      .mr-25 {
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  /* For portrait layouts only */
  .caselist__container {
    .editsearch-container-mobile {
      display: block;
      border-bottom: 1px solid $greyscale-grey-5;
      padding: 5px 0;
    }
    .editsearch-container-web {
      display: none;
    }
    }
}