@mixin normal-font {
  font-family: Roboto-Regular, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  font-weight: normal;
  font-style: normal;
}

.triage-row {
  &--attention {
    background-color: $alert-highlight-warning;
  }
  display: flex;
  align-items: baseline;
  gap: 10px;
  border-bottom: 0.75px solid $greyscale-grey-6;
  padding: 09px 0 09px 16px;
  @media only screen and (max-width: 450px) {
    padding: 11px 0 11px 16px;
  }

  &:last-of-type {
    border-bottom: none;
  }

  &__info {
    & > p {
      @include normal-font;
      margin-right: 6px;
    }

    &:first-child {
      min-width: 150px;
      max-width: 150px;
    }
  }

  &__title {
    color: $greyscale-grey-8;
    font-size: 14px;
  }

  &__icon {
    margin: 0 0 -3px 6px;
  }

  &__children {
    color: $greyscale-grey-9;
    font-size: 14px;
    display: flex;
    flex-wrap: wrap;
  }

  //Row Badge
  &__badge {
    @include normal-font;
    font-size: 14px;
    padding: 04px;
    margin-left: 8px;
    background-color: $alert-warning;
    display: inline-flex;
    gap: 08px;
    color: white;
  }
  &__badge-icon {
    path {
      stroke: white;
    }
  }

  @media only screen and (max-width: 450px) {
    flex-direction: column;
    gap: 4px;
    align-items: flex-start;
    &__children {
      font-size: 16px;
    }
  }
}

//Triage Recommendation component
.triage-recc {
  .panel__body {
    border-bottom: none;
  }
  margin-top: 24px;
  &__badge {
    font-size: 14px;
    padding: 04px;
    margin: 4px 4px 0 0;
  }
}

//Triage Header component
.triage-header {
  padding: 8px 16px 12px 16px;
  border-bottom: 1px solid $greyscale-grey-6;

  &__heading {
    @include normal-font;
    color: $greyscale-grey-9;
    font-size: 18px;
    @media only screen and (max-width: 450px) {
      font-size: 20px;
    }
  }
  &__info {
    display: flex;
    align-items: center;

    gap: 6px;
    margin: 4px 0;
    @media only screen and (max-width: 450px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
      font-size: 20px;
    }
  }
  &__para {
    @include normal-font;
    color: $greyscale-grey-6;
    font-size: 14px;
  }
  &__date {
    .bullet {
      display: inline-block;
      margin-right: 4px;
      &__icon {
        margin-top: -7px;
      }
    }
    @include normal-font;
    color: $greyscale-grey-9;
    font-size: 12px;

    @media only screen and (max-width: 450px) {
      font-size: 14px;
    }
  }

  &__badge {
    font-size: 14px;
    padding: 10px;
    margin: 0;
  }
}

//Triage country Icon
.triage-testing {
  .panel__body {
    border-bottom: none;
  }
  &__countryIcon {
    margin-right: 10px;
  }
}

//
