.filterButton {
  border: 1px solid $preventx-blue;
  justify-content: space-between;
  align-items: center;
  width: 72px;
  border-radius: 8px;
  padding: 8px !important;
  font-size: 14px;
  font-weight: normal;
  cursor: pointer;
  path {
    stroke: $preventx-blue;
  }

  > div {
    position: relative !important;
  }
}
