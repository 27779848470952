.cup {
  &-online {
    position: relative;
    display: flex;
    width: 100%;
  }
  &-offline {
    padding: 14px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    inset: 0;
    z-index: 1000;
  }
}
