.Caselist__Row {
  display: flex;
  align-items: center;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid $greyscale-grey-5;
  background: #fff;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: 16px;
  .personalDetails,
  .contactDetails,
  .statusDetails {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    .badge {
      margin-bottom: 8px;
    }
    .casestatus {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .flex-container {
    display: flex;
    align-items: center;
  }
  .date_container {
    display: flex;
    align-items: center;
    color: $greyscale-grey-8;
  }
  .mr-8 {
    margin-right: 8px;
  }
  .mb-8 {
    margin-bottom: 8px;
  }
  .mt-8 {
    margin-top: 8px;
  }
  .ml-4 {
    margin-left: 4px;
  }
  .arrow-right {
    margin-right: 10px;
  }
}
