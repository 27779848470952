.error-loading-blk {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: white;
  z-index: 9999;
  .sb-loading-block {
    position: absolute;
    left: 50%;
    top: 50%;
  }
}
