.user-list-header {
  border-bottom: 1px solid $greyscale-grey-5;
  font-size: 16px;
  color: $greyscale-grey-9;
  padding: 12px 0;
  padding-left: 30px;
  padding-right: 96px;

  @media only screen and (min-width: 1025px) {
    padding: 12px 0;
    padding-left: 40px;
    padding-right: 108px;
  }

  @media only screen and (max-width: 499px) {
    display: none;
  }
}
