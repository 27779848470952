// MIXIN: Animations

@mixin transform($rot, $scale1, $scale2) {
  -webkit-transform: rotate($rot) scale($scale1, $scale2);
  -ms-transform: rotate($rot) scale($scale1, $scale2);
  transform: rotate($rot) scale($scale1, $scale2);
}

@mixin keyframeAnimation($name, $duration, $count, $function) {
  -moz-animation: $name, $duration, $count, $function;
  -webkit-animation: $name, $duration, $count, $function;
  -ms-animation: $name, $duration, $count, $function;
  -o-animation: $name, $duration, $count, $function;
  animation: $name, $duration, $count, $function;
}

// MIXIN: Transition
@mixin transition($property, $duration, $timing-function, $delay) {
  -webkit-transition: $property $duration $timing-function $delay;
  -moz-transition: $property $duration $timing-function $delay;
  -o-transition: $property $duration $timing-function $delay;
  transition: $property $duration $timing-function $delay;
}

@mixin simpleTransition($transition...) {
  -webkit-transition: $transition;
  -moz-transition: $transition;
  -o-transition: $transition;
  transition: $transition;
}

// MIXIN: Border Radius
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

// MIXIN: Box Shadow
@mixin box-shadow($shadow...) {
  -webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  box-shadow: $shadow;
}

// MIXIN: Opacity
@mixin opacity($trans) {
  filter: alpha(opactiy=($trans * 100));
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=#{$trans * 100})";
  -moz-opacity: $trans;
  -khtml-opacity: $trans;
  opacity: $trans;
}

// MIXIN: Transform
@mixin transform($rot, $scale1, $scale2) {
  -webkit-transform: rotate($rot) scale($scale1, $scale2);
  -moz-transform: rotate($rot) scale($scale1, $scale2);
  -o-transform: rotate($rot) scale($scale1, $scale2);
  -ms-transform: rotate($rot) scale($scale1, $scale2);
  transform: rotate($rot) scale($scale1, $scale2);
}

// MIXIN: Transform: Scale
@mixin transform($scale1, $scale2) {
  -webkit-transform: scale($scale1, $scale2);
  -moz-transform: scale($scale1, $scale2);
  -o-transform: scale($scale1, $scale2);
  -ms-transform: scale($scale1, $scale2);
  transform: scale($scale1, $scale2);
}

// MIXIN: Transform Rotate
@mixin rotate($rot) {
  -webkit-transform: rotate($rot);
  -moz-transform: rotate($rot);
  -o-transform: rotate($rot);
  -ms-transform: rotate($rot);
  transform: rotate($rot);
}

/*** MIXIN: Flexbox ************************************************************/
@mixin flexbox {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}

@mixin inline-flex {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

@mixin flex($values) {
  -webkit-box-flex: $values;
  -moz-box-flex: $values;
  -webkit-flex: $values;
  -ms-flex: $values;
  flex: $values;
}

@mixin flex-direction($value: row) {
  /* !row! | row-reverse | column | column-reverse */
  @if $value == row-reverse {
    -webkit-box-direction: reverse;
    -webkit-box-orient: horizontal;
  } @else if $value == column {
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
  } @else if $value == column-reverse {
    -webkit-box-direction: reverse;
    -webkit-box-orient: vertical;
  } @else {
    -webkit-box-direction: normal;
    -webkit-box-orient: horizontal;
  }
  -webkit-flex-direction: $value;
  -moz-flex-direction: $value;
  -ms-flex-direction: $value;
  flex-direction: $value;
}

@mixin flex-wrap($value: nowrap) {
  /* !nowrap! | wrap | wrap-reverse */
  // No Webkit Box fallback.
  -webkit-flex-wrap: $value;
  -moz-flex-wrap: $value;
  @if $value == nowrap {
    -ms-flex-wrap: none;
  } @else {
    -ms-flex-wrap: $value;
  }
  flex-wrap: $value;
}

@mixin flex-flow(
  $values: (
    row nowrap
  )
) {
  /* Combination of flex-direction AND flex-wrap, default is row AND nowrap */
  // No Webkit Box fallback.
  -webkit-flex-flow: $values;
  -moz-flex-flow: $values;
  -ms-flex-flow: $values;
  flex-flow: $values;
}

@mixin flex-grow($int: 0) {
  -webkit-box-flex: $int;
  -webkit-flex-grow: $int;
  -moz-flex-grow: $int;
  -ms-flex-positive: $int;
  flex-grow: $int;
}

@mixin flex-shrink($int: 1) {
  -webkit-flex-shrink: $int;
  -moz-flex-shrink: $int;
  -ms-flex-negative: $int;
  flex-shrink: $int;
}

@mixin flex-basis($value: auto) {
  -webkit-flex-basis: $value;
  -moz-flex-basis: $value;
  -ms-flex-preferred-size: $value;
  flex-basis: $value;
}

@mixin justify-content($value: flex-start) {
  /* !flex-start!, flex-end, center, space-between, space-around */
  @if $value == flex-start {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
  } @else if $value == flex-end {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
  } @else if $value == space-between {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
  } @else {
    -webkit-box-pack: $value;
    -ms-flex-pack: $value;
  }
  -webkit-justify-content: $value;
  -moz-justify-content: $value;
  justify-content: $value;
}

@mixin align-items($value: stretch) {
  /* flex-start, flex-end, center, baseline, !stretch! */
  @if $value == flex-start {
    -webkit-box-align: start;
    -ms-flex-align: start;
  } @else if $value == flex-end {
    -webkit-box-align: end;
    -ms-flex-align: end;
  } @else {
    -webkit-box-align: $value;
    -ms-flex-align: $value;
  }
  -webkit-align-items: $value;
  -moz-align-items: $value;
  align-items: $value;
}

@mixin align-self($value: auto) {
  /* !auto! | flex-start | flex-end | center | baseline | stretch */
  // No Webkit Box Fallback.
  -webkit-align-self: $value;
  -moz-align-self: $value;
  @if $value == flex-start {
    -ms-flex-item-align: start;
  } @else if $value == flex-end {
    -ms-flex-item-align: end;
  } @else {
    -ms-flex-item-align: $value;
  }
  align-self: $value;
}

@mixin align-content($value: stretch) {
  /* flex-start | flex-end | center | space-between | space-around | !stretch! */
  // No Webkit Box Fallback.
  -webkit-align-content: $value;
  -moz-align-content: $value;
  @if $value == flex-start {
    -ms-flex-line-pack: start;
  } @else if $value == flex-end {
    -ms-flex-line-pack: end;
  } @else {
    -ms-flex-line-pack: $value;
  }
  align-content: $value;
}

/* Vertical Align Mixin */
@mixin vertical-align {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

/** Media Query Mixins, Breakpoints **/
@mixin breakpoint($name) {
  // START: Custom breakpoints/media-quieries
  @if $name == "IE" {
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      @content;
    }
  }
  @if $name == "Edge" {
    @supports (-ms-ime-align: auto) {
      @content;
    }
  }
  @if $name == "landscape" {
    @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
      @content;
    }
  }
  @if $name == "landscape-plus" {
    @media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
      @content;
    }
  }
  @if $name == "landscape-plus-x" {
    @media only screen and (min-device-width: 737px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
      @content;
    }
  }
  @if $name == "windows-phone" {
    @media screen and (-ms-high-contrast: active) and (max-width: 599px),
      (-ms-high-contrast: none) and (max-width: 599px) {
      @content;
    }
  }
  // END: Custom breakpoints/media-quieries
}

@mixin font-size($list, $breakpoints) {
  @each $name, $size in $list {
    @if $name==null {
      font-size: $size;
    } @else {
      @if map-has-key($breakpoints, $name) {
        $name: map-get($breakpoints, $name);
      }
      @media screen and (max-width:#{$name}) {
        font-size: $size;
      }
    }
  }
}

@mixin color($type, $color) {
  @if $type == font {
    color: $color;
  } @else if $type == bg {
    background-color: $color;
  }
}

@mixin font($value: Roboto-Regular) {
  font-family: $value;
}

@mixin fonts($font: regular) {
  @if ($font==regular) {
    font-family: Roboto-Regular, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  } @else if ($font==medium) {
    font-family: Roboto-Medium, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  } @else if ($font==bold) {
    font-family: Roboto-Bold, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  }
  font-style: normal;
  font-stretch: normal;
}

@mixin font-size-weight($size: 48px, $weight: normal) {
  font-weight: $weight;
  font-size: $size;
}

@mixin lineheight-letterspace($lineHeight: 1.17, $letterSpace: 0.5px) {
  line-height: $lineHeight;
  letter-spacing: $letterSpace;
}

@mixin flex-align-center() {
  @include flexbox();
  @include align-items(center);
}

@mixin flex-align-justify-center() {
  @include flex-align-center();
  justify-content: center;
}

@mixin DesktopH2Bold {
  @include fonts(bold);
  @include font-size-weight(48px, bold);
  @include lineheight-letterspace();
}

@mixin DesktopH1Medium {
  @include fonts(bold);
  @include font-size-weight(48px, bold);
  @include lineheight-letterspace();
}

@mixin DesktopH1Bold {
  @include fonts(bold);
  @include font-size-weight(48px, bold);
  @include lineheight-letterspace();
}

@mixin DesktopH1Regular {
  @include fonts(regular);
  @include font-size-weight(48px, normal);
  @include lineheight-letterspace();
}

@mixin DesktopH2Medium {
  @include fonts(medium);
  @include font-size-weight(40px, 500);
  @include lineheight-letterspace(1.2);
}

@mixin DesktopH2Regular {
  @include fonts(regular);
  @include font-size-weight(40px, normal);
  @include lineheight-letterspace(1.2);
}

@mixin DesktopH3Bold {
  @include fonts(bold);
  @include font-size-weight(32px, bold);
  @include lineheight-letterspace(1.25, -0.5px);
}

@mixin MobileH1Medium {
  @include fonts(medium);
  @include font-size-weight(32px, 500);
  @include lineheight-letterspace(1.25, -0.5px);
}

@mixin DesktopH3Medium {
  @include fonts(medium);
  @include font-size-weight(32px, 500);
  @include lineheight-letterspace(1.25, -0.5px);
}

@mixin MobileH1Regular {
  @include fonts(regular);
  @include font-size-weight(32px, normal);
  @include lineheight-letterspace(1.25, -0.5px);
}

@mixin DesktopH3Regular {
  @include fonts(regular);
  @include font-size-weight(32px, normal);
  @include lineheight-letterspace(1.25, -0.5px);
}

@mixin MobileH2Medium {
  @include fonts(medium);
  @include font-size-weight(28px, 500);
  @include lineheight-letterspace(1.14, -0.5px);
}

@mixin DesktopH4Bold {
  @include fonts(bold);
  @include font-size-weight(24px, bold);
  @include lineheight-letterspace(1.33, -0.5px);
}

@mixin MobileH3Medium {
  @include fonts(medium);
  @include font-size-weight(24px, 500);
  @include lineheight-letterspace(1.33, -0.5px);
}

@mixin DesktopH4Medium {
  @include fonts(medium);
  @include font-size-weight(24px, 500);
  @include lineheight-letterspace(1.33, -0.5px);
}

@mixin DesktopH4Regular {
  @include fonts(regular);
  @include font-size-weight(24px, normal);
  @include lineheight-letterspace(1.33, -0.5px);
}

@mixin DesktopH5Bold {
  @include fonts(bold);
  @include font-size-weight(20px, bold);
  @include lineheight-letterspace(1.2, -0.5px);
}

@mixin MobileH4Medium {
  @include fonts(medium);
  @include font-size-weight(20px, 500);
  @include lineheight-letterspace(1.2, -0.5px);
}

@mixin DesktopH5Medium {
  @include fonts(medium);
  @include font-size-weight(20px, 500);
  @include lineheight-letterspace(1.2, -0.5px);
}

@mixin DesktopH5Regular {
  @include fonts(regular);
  @include font-size-weight(20px, normal);
  @include lineheight-letterspace(1.2, -0.5px);
}

@mixin MobileH5Medium {
  @include fonts(medium);
  @include font-size-weight(16px, 500);
  @include lineheight-letterspace(1.2, -0.5px);
}

@mixin DesktoppMedium {
  @include fonts(medium);
  @include font-size-weight(16px, 500);
  @include lineheight-letterspace(1.25, normal);
}

@mixin MobileH5Regular {
  @include fonts(regular);
  @include font-size-weight(16px, normal);
  @include lineheight-letterspace(1.25, normal);
}

@mixin MobileButton {
  @include fonts(regular);
  @include font-size-weight(16px, normal);
  @include lineheight-letterspace(1.5, normal);
}

@mixin DesktoppRegular {
  @include fonts(regular);
  @include font-size-weight(16px, normal);
  @include lineheight-letterspace(1.25, normal);
}

@mixin DesktopButtonsMedium {
  @include fonts(medium);
  @include font-size-weight(14px, 500);
  @include lineheight-letterspace(1.14, normal);
}

@mixin Mobilep {
  @include fonts(regular);
  @include font-size-weight(14px, normal);
  @include lineheight-letterspace(1.43, normal);
}

@mixin MobileLabel {
  @include fonts(regular);
  @include font-size-weight(14px, normal);
  @include lineheight-letterspace(1.29, normal);
}

@mixin DesktopButtonsRegular {
  @include fonts(regular);
  @include font-size-weight(14px, normal);
  @include lineheight-letterspace(1.14, normal);
}

@mixin DesktopLabelsMedium {
  @include fonts(medium);
  @include font-size-weight(12px, 500);
  @include lineheight-letterspace(1.33, normal);
}

@mixin DesktopLabelsRegular {
  @include fonts(regular);
  @include font-size-weight(12px, normal);
  @include lineheight-letterspace(1.33, normal);
}

@mixin DesktopNumbersBold {
  @include fonts(bold);
  @include font-size-weight(10px, bold);
  @include lineheight-letterspace(1.1, normal);
}

@mixin DesktopH6Bold {
  @include fonts(bold);
  @include font-size-weight(10px, bold);
  @include lineheight-letterspace(1.4, normal);
}

@mixin DesktopNumbersMedium {
  @include fonts(medium);
  @include font-size-weight(10px, 500);
  @include lineheight-letterspace(1.4, 0.5px);
}

@mixin DesktopH6Medium {
  @include fonts(medium);
  @include font-size-weight(10px, 500);
  @include lineheight-letterspace(1.4, normal);
}

@mixin DesktopNumbersRegular {
  @include fonts(regular);
  @include font-size-weight(10px, normal);
  @include lineheight-letterspace(1.4, normal);
}

@mixin DesktopH6Regular {
  @include fonts(regular);
  @include font-size-weight(10px, normal);
  @include lineheight-letterspace(1.4, normal);
}

@mixin PanelTitle {
  @include fonts(regular);
  @include font-size-weight(18px, 400);
  @include lineheight-letterspace(1.25, -0.25px);
}

@mixin screen($screen: "tab") {
  @if ($screen== "tab") {
    @media screen and (max-width: 769px) {
      @content;
    }
  } @else if ($screen== "mobile") {
    @media screen and (max-width: 361px) {
      //mobile range from 450px
      @content;
    }
  }
}

@mixin chips($bg, $color, $stroke) {
  background-color: $bg;

  .chips-label:not(:last-child):after {
    content: "";
    border-left: 1px solid $color;
    margin: 0 4px;
    opacity: 0.4;
    border-radius: 2px;
  }

  .chips-label {
    color: $color;
  }
  .icon {
    background: $color;
    path {
      stroke: $stroke;
    }
  }
}

@mixin chips2($bg, $color) {
  background-color: $bg;

  .chips-label:not(:last-child):after {
    content: "";
    border-left: 1px solid $color;
    margin: 0 4px;
    opacity: 0.4;
    border-radius: 2px;
  }

  .chips-label {
    color: $color;
  }
}
