// $preventx-blue: #04306b;
// $preventx-pink: #cd1283;

// $greyscale-grey-9: #151d26;
// $paragraph: #244975;
// $greyscale-grey-4: #517394;
// $label: #1d3854;
// $greyscale-white: #fff;
// $greyscale-grey-1: #f1f3f6;
// $greyscale-grey-2: #e2e4e9;
// $greyscale-grey-3: #d9dce4;
// $greyscale-grey-4: #cfd4dc;
// $greyscale-grey-5: #b8bec9;
// $greyscale-grey-6: #8b93a2;
// $greyscale-grey-7: #68707e;
// $greyscale-grey-8: #57576b;
// $greyscale-grey-9: #161e27;
// $teal: #63b7c0;
// $rasberry: #b53854;
// $purple: #6a4dee;
// $soft-pink: #ec91a8;
// $lime: #a7c74a;
// $alert-warning: #de9654;
// $soft-blue: #4973ec;
// $yellow: #eacf67;
// $overlay: #444a51;

// $sky-blue: #1074b9;
// $preventx-blue: #2667f5;
// $sh-blue: #2391c9;

// $alert-success: #237045;
// $alert-error: #e72323;
// $alert-warning: #d06d00;

// $preventx-blue: #142972;
// $alert-success-lite: #e8f8ef;
// $alert-highlight-warning: #ffe9cf;
// $alert-error-lite: #fce7e7;

// $accents-general: #afd4fa;

// $accents-portal-blue: #e8eaf1;

$preventx-blue: #0026c5;
$preventx-teal: #88dbdf;
$preventx-green: #00b388;
$preventx-light-blue: #00a3e0;
$preventx-dark-blue: #002677;
$preventx-dark-purple: #582c83;
$preventx-purple: #7664b1;
$preventx-light-purple: #d6bfdd;
$preventx-pink: #af1685;

$greyscale-white: #ffffff;
$greyscale-grey-1: #f1f3f6;
$greyscale-grey-2: #e2e4e9;
$greyscale-grey-3: #d9dce4;
$greyscale-grey-4: #cfd4dc;
$greyscale-grey-5: #b8bec9;
$greyscale-grey-6: #8b93a2;
$greyscale-grey-7: #68707e;
$greyscale-grey-8: #57576b;
$greyscale-grey-9: #151d26;

$alert-success: #237045;
$alert-warning: #d16c00;
$alert-error: #e72323;
$alert-highlight-success: #e9f1ec;
$alert-highlight-warning: #faf0e5;
$alert-highlight-error: #fce8e8;
$alert-highlight-general: #afd4fa;
$utility-overlay: #161e27;
$bg-color:#EFF2F5;

$breakpoints: (
  xs: 600px,
  sm: 960px,
  md: 1280px,
  lg: 1920px
);

$h1-font-size: (
  xs: 32px,
  md: 48px,
  null: 48px
);

$h2-font-size: (
  xs: 28px,
  md: 40px,
  null: 40px
);

$h3-font-size: (
  xs: 24px,
  md: 32px,
  null: 32px
);

$h4-font-size: (
  xs: 20px,
  md: 24px,
  null: 24px
);

$h5-font-size: (
  xs: 16px,
  md: 20px,
  null: 20px
);

$h6-font-size: (
  xs: 10px,
  md: 10px,
  null: 10px
);

$p-font-size: (
  xs: 14px,
  md: 16px,
  null: 16px
);

$button-font-size: (
  xs: 16px,
  md: 14px,
  null: 14px
);

$label-font-size: (
  xs: 14px,
  md: 12px,
  null: 12px
);

$number-font-size: (
  md: 10px,
  null: 12px
);

$breakpoints: (
  xs: 600px,
  sm: 960px,
  md: 1280px,
  lg: 1920px
);

$h1-font-size: (
  xs: 32px,
  md: 48px,
  null: 48px
);

$h2-font-size: (
  xs: 28px,
  md: 40px,
  null: 40px
);

$h3-font-size: (
  xs: 24px,
  md: 32px,
  null: 32px
);

$h4-font-size: (
  xs: 20px,
  md: 24px,
  null: 24px
);

$h5-font-size: (
  xs: 16px,
  md: 20px,
  null: 20px
);

$h6-font-size: (
  xs: 10px,
  md: 10px,
  null: 10px
);

$p-font-size: (
  xs: 14px,
  md: 16px,
  null: 16px
);

$button-font-size: (
  xs: 16px,
  md: 14px,
  null: 14px
);

$label-font-size: (
  xs: 14px,
  md: 12px,
  null: 12px
);

$number-font-size: (
  md: 10px,
  null: 12px
);
