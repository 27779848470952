.message-list-container {
  .text-low {
    text-transform: lowercase;
  }
  .hide-cotentlist{
    visibility: hidden;
  }
  .show-cotent{
    display: block;
    position: absolute;
    width: 90%;
  }
  .hide-content{
    display: none;
  }
  .hide-detail{
    display: none;
  }
  .show-detail{
    display: block;
  }
  &__content{
    background-color: $bg-color;
  }
}
