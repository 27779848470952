.text-primary {
  @include color(font, $preventx-blue);
}

.text-secondary {
  @include color(font, $preventx-pink);
}

.text-white {
  @include color(font, $greyscale-white);
}

.text-muted {
  @include color(font, $greyscale-grey-4);
}

.bg-primary {
  @include color(bg, $preventx-blue);
}

.bg-secondary {
  @include color(bg, $preventx-pink);
}

.bg-success {
  @include color(bg, $alert-success);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @include color(font, $greyscale-grey-9);
}
