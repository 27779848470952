.triages-title {
  padding: 16px 16px;
}
.triages-tab {
  display: flex;
  flex-direction: column;
  width: 96%;
  margin-bottom: 20px;
}
.triages-card {
  margin-left: 17px;
  &__info {
    border-bottom: 0.75px solid $greyscale-grey-6;
  }
  &__content {
    border: 0.75px solid $greyscale-grey-6;
    width: 96%;
    border-radius: 8px;
    box-shadow: 0 0.05px grey;
    margin-bottom: 16px;
  }
  &__title {
    display: flex;
    justify-content: space-between;
    padding: 8px 16px;
    font-size: 18px;
  }
  &__sub-title {
    color: $preventx-blue;
    font-size: 14px;
    svg {
      margin-left: 13px;
    }
  }
  &__date {
    display: flex;
    justify-content: flex-start;
    font-size: 12px;
    margin-left: 17px;
  }
  &__badge {
    margin: 8px 17px;
    .badge {
      flex-direction: row-reverse;
    }
    svg {
      height: 17px;
      margin-left: 4px;
    }
  }
  &__description {
    .badge {
      margin-right: 2px;
    }
    svg {
      height: 17px;
    }
  }
}
